import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';

/* tslint:disable */
import WebIcon from '../images/web.svg';
import ServerIcon from '../images/server.svg';
import ToolsIcon from '../images/tools.svg';
/* tslint:enable */

import {
    Navbar,
    Footer,
    ProjectHero,
    Header,
    Section,
    Contact,
} from '../components';

const skills = [
    {
        title: 'Frontend',
        Icon: WebIcon,
        skills: [
            'React',
            'React Native',
            'Vue',
            'JavaScript',
            'TypeScript',
            'HTML',
            'CSS',
            'Bootstrap',
            'Tailwind',
            'UI/UX in Figma',
        ],
    },
    {
        title: 'Backend',
        Icon: ServerIcon,
        skills: [
            'PHP',
            'Node.js',
            'Next.js',
            'Gatsby.js',
            'Nest.js',
            'REST APIs',
            'GraphQL',
            'Apollo',
            'Prisma',
            'SQL',
            'MySQL',
            'PostgreSQL',
            'MongoDB',
        ],
    },
    {
        title: 'Tools',
        Icon: ToolsIcon,
        skills: [
            'AWS',
            'Terraform',
            'Ansible',
            'Linux',
            'Git',
            'Webpack',
            'NX',
            'Docker',
            'Nginx',
            'Sendgrid',
        ],
    },
];

const IndexPage = () => {
    return (
        <main className="">
            <Helmet>
                <title>Jasulan - Fullstack Developer</title>
            </Helmet>
            <Navbar />
            <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-32 lg:py-64 flex items-center justify-center">
                <div>
                    <h1 className="block font-medium text-4xl md:text-5xl leading-6">
                        <span className="text-gradient">Hey, I'm Jasulan </span>
                        <span className="text-gradient text-5xl md:text-7xl font-bold block mt-6">
                            A Fullstack Developer
                        </span>
                    </h1>
                    <div className="mt-8 text-xl">
                        I love building apps that help users become awesome at
                        what they do.
                    </div>
                </div>
            </section>
            <section id="projects">
                <div className="mx-auto max-w-7xl lg:px-8">
                    <header className="mb-20">
                        <Header>Projects</Header>
                    </header>
                    <ProjectHero
                        title="Ecommerce SAAS"
                        url="/ecomsaas"
                        subtitle="In progress"
                        image={
                            <div className="mt-[3rem]">
                                <StaticImage
                                    className="w-64 rounded-t-lg shadow-xl shadow-black"
                                    src="../images/ecom-design-builder.png"
                                    alt=""
                                />
                            </div>
                        }
                    >
                        Ecommerce SAAS platform that will help store-owners
                        create their own ecommerce website using WYSIWYG
                        builder.
                    </ProjectHero>
                    <ProjectHero
                        title="BlogIdeas"
                        url="/blogideas"
                        subtitle={
                            <a
                                target="_blank"
                                className="hover:underline group-hover:underline"
                                href="https://blogideas.xyz"
                            >
                                Visit
                            </a>
                        }
                        image={
                            <div className="relative mt-8 h-full w-full">
                                <div className="absolute right-0 top-0 mt-2 lg:mt-2">
                                    <StaticImage
                                        className="w-[18rem] opacity-75 rounded-tl-lg lg:rounded-bl-xl shadow-2xl shadow-black"
                                        src="../images/related-queries.png"
                                        alt=""
                                    />
                                </div>
                                <div className="absolute bottom-[-1.5rem] right-0 mt-40 md:mt-32 lg:mt-44 shadow-xl md:shadow-black">
                                    <StaticImage
                                        className="lg:w-[30rem] lg:rounded-tl-lg lg:rounded-br-xl shadow md:shadow-black shadow-opacity-95"
                                        src="../images/search-analysis.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                        }
                    >
                        A powerful tool that helps bloggers find blog-post ideas
                        analyzing popular search terms.
                    </ProjectHero>
                    <ProjectHero
                        title="DesignShuffle"
                        url="/designshuffle"
                        subtitle={
                            <a
                                target="_blank"
                                className="hover:underline group-hover:underline"
                                href="https://designshuffle.xyz"
                            >
                                Visit
                            </a>
                        }
                        image={
                            <div className="absolute bottom-0 right-0">
                                <StaticImage
                                    className="lg:w-[36rem] lg:rounded-tl-lg lg:rounded-br-xl shadow-2xl shadow-black"
                                    src="../images/page-builder.png"
                                    alt=""
                                />
                            </div>
                        }
                    >
                        A website builder tool that helps designing templates in
                        minutes.
                    </ProjectHero>
                </div>
            </section>
            <Section id="about" header="About Me">
                <div className="md:flex items-start text-lg lg:text-xl text-secondary-200">
                    <div className="w-full md:w-1/2 leading-8">
                        <p>
                            Hello! My name is Jasulan and I love building
                            software. I became interested in programming back in
                            2010 and since then continued learning.
                        </p>
                        <p className="mt-4">
                            For the past 9+ years I've worked as a freelancer
                            and had the opportunity to solve interesting
                            problems and create memorable websites and apps.
                            {` `}
                        </p>
                    </div>
                    <div className="mt-4 md:mt-0 w-full md:w-1/2 leading-8 md:pl-4">
                        <p>
                            I love the fact that as a fullstack-developer, I can
                            focus not only on one part of building software but
                            also on many moving parts which allows me to build
                            software that is not only reliable and maintanable
                            but also has good design and UX.
                        </p>
                    </div>
                </div>
            </Section>
            <Section id="skills" header="Skills">
                <div className="md:flex items-start -mx-4">
                    {skills.map((skill, index) => (
                        <div
                            key={`skills-${index}`}
                            className="md:w-1/3 px-4 mb-8 md:mb-0"
                        >
                            <div className="h-80 flex items-center py-10 px-4 rounded-xl shadow-lg hover:shadow-black border border-secondary-800 border-opacity-50">
                                <div className="flex-col items-center justify-center text-center">
                                    <div className="inline-flex bg-primary-500 rounded-full h-16 w-16 items-center justify-center">
                                        <skill.Icon className="h-8 w-8" />
                                    </div>
                                    <h3 className="text-2xl font-bold my-4 leading-8">
                                        {skill.title}
                                    </h3>
                                    <div className="text-sm text-secondary-400">
                                        {skill.skills.join(', ')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Section>
            <Contact />
            <Footer />
        </main>
    );
};

export default IndexPage;
